export const OperatorsShortnames = (operator: string) => {
  const lowerCaseOperator = operator.toLowerCase();
  if (lowerCaseOperator.startsWith('flixbus')) {
    return 'FlixBus';
  } else if (lowerCaseOperator.startsWith('db')) {
    return 'DB';
  } else if (lowerCaseOperator.startsWith('máv-start')) {
    return 'MAV Start';
  }
  switch (lowerCaseOperator) {
    case 'sncf':
    case 'sncf voyages':
    case 'sncf voyageurs':
      return 'SNCF';
    case 'ter auvergne-rhône-alpes':
      return 'SNCF';
    case 'danske statsbaner-passenger subsidiary':
      return 'DSB';
    case 'db fernverkehr ag':
    case 'db regio ag baden-württemberg':
    case 'deutsche bahn':
    case 'db':
      return 'DB';
    case 'verkehrs- und tarifverbund stuttgart':
      return 'VVS';
    case 'bayerische regiobahn brb':
      return 'BRB';
    case 'national railway company of belgium':
    case 'société nationale des chemins de fer belges':
      return 'SNCB';
    case 'obb':
    case 'öbb personenverkehr ag':
    case 'österreichische bundesbahnen':
      return 'OBB';
    case 'swiss federal railways-passenger subsidiary':
    case 'schweizerische bundesbahnen':
      return 'SBB';
    case 'ns international':
    case 'nederlandse spoorwegen':
    case 'ns groep n.v.':
    case 'ns':
      return 'NS';
    case 'trenitalia':
    case 'ferrovie dello stato spa':
      return 'Trenitalia';
    case 'flix':
    case 'flixbus':
    case 'starshipper sas':
      return 'FlixBus';
    case 'flixtrain':
    case 'flixtrain gmbh':
      return 'FlixTrain';
    case 'eurolines':
      return 'Eurolines';
    case 'ecolines':
      return 'Ecolines';
    case 'comboios':
      return 'Comboios';
    case 'elron':
      return 'Elron';
    case 'lux':
      return 'LuxExpress';
    case 'blablabus':
      return 'BlaBlaBus';
    case 'blablacar':
      return 'BlaBlaCar 🚗';
    case 'máv-start vasúti személyszállító zrt.':
      return 'MAV Start';
    case 'mav':
      return 'Mav';
    case 'google':
      return 'FlixBus';
    case 'westbahn':
      return 'WESTBahn + Flixbus 🚄🚌';
    case 'union ivkoni':
      return 'Union Ivkoni';
    case 'renfe':
      return 'Renfe';
    case 'alsa':
      return 'Alsa';
    case 'iryo':
      return 'Iryo';
    case 'db regio ag nwr':
    case 'db regio ag mitte':
      return 'DB Regio';
    case 'eurobahn':
    case 'euroBahn':
      return 'EB';
    case 'zelezničná spoločnosť slovensko':
    case 'železničná spoločnosť slovensko, a.s.':
      return 'ZSSK';
    case 'h putnicki prijevoz d.o.o. za prijevoz putnika':
      return 'HZPP';
    case 'ceské dráhy, a.s.':
      return 'CD';
    case 'italo treno':
      return 'Italo';
    case 'caledonian sleeper':
      return 'Caledonian';
    case 'avanti west coast':
      return 'Avanti';
    default:
      return operator.trim();
  }
};
