const NightjetNewGenCabins = [
  {
    type: 'seat',
    price_ratio: 0.56,
    pictures: [
      '/assets/night-trains-images/nightjet-newgen/seat.jpg',
      '/assets/night-trains-images/nightjet-newgen/seat2.jpg',
      '/assets/night-trains-images/nightjet-newgen/seat3.jpg',
      '/assets/night-trains-images/nightjet-newgen/seat4.jpg',
      '/assets/night-trains-images/nightjet-newgen/seat5.jpg',
    ],
    pictures_author: 'ÖBB/Harald Eisenberger',
    amenities: ['wifi', 'power', 'air', 'reading'],
  },
  {
    type: 'couchette',
    default: true,
    price_ratio: 1,
    pictures: [
      '/assets/night-trains-images/nightjet-newgen/couchette.jpg',
      '/assets/night-trains-images/nightjet-newgen/couchette2.jpg',
      '/assets/night-trains-images/nightjet-newgen/couchette3.jpg',
      '/assets/night-trains-images/nightjet-newgen/couchette4.jpg',
      '/assets/night-trains-images/nightjet-newgen/couchette5.jpg',
      '/assets/night-trains-images/nightjet-newgen/couchette6.jpg',
    ],
    pictures_author: 'ÖBB/Harald Eisenberger',
    amenities: ['wifi', 'power', 'air', 'reading', 'pillow', 'blanket', 'waterBottle'],
    menu: 'https://www.nightjet.com/dam/jcr:5dc4bcc6-8840-47b8-973a-3a7db2cf3c44/speisekarte-liegewagen.pdf',
  },
  {
    type: 'minicabin',
    price_ratio: 1.87,
    pictures: [
      '/assets/night-trains-images/nightjet-newgen/minicabin.jpg',
      '/assets/night-trains-images/nightjet-newgen/minicabin2.jpg',
      '/assets/night-trains-images/nightjet-newgen/minicabin3.jpg',
      '/assets/night-trains-images/nightjet-newgen/minicabin4.jpg',
      '/assets/night-trains-images/nightjet-newgen/minicabin5.jpg',
      '/assets/night-trains-images/nightjet-newgen/minicabin6.jpg',
      '/assets/night-trains-images/nightjet-newgen/minicabin7.jpg',
      '/assets/night-trains-images/nightjet-newgen/minicabin8.jpg',
      '/assets/night-trains-images/nightjet-newgen/minicabin9.jpg',
    ],
    pictures_author: 'ÖBB/Harald Eisenberger',
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'breakfast',
      'locker',
      'pillow',
      'blanket',
      'waterBottle',
    ],
    menu: 'https://www.nightjet.com/dam/jcr:5dc4bcc6-8840-47b8-973a-3a7db2cf3c44/speisekarte-liegewagen.pdf',
  },
  {
    type: 'comfort',
    price_ratio: 2.155,
    pictures: [
      '/assets/night-trains-images/nightjet-newgen/comfort.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort2.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort3.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort4.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort5.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort6.jpg',
    ],
    pictures_author: 'ÖBB/Harald Eisenberger',
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'shower',
      'toilet',
      'breakfast',
      'pillow',
      'blanket',
      'waterBottle',
    ],
    breakfastMenu:
      'https://www.nightjet.com/dam/jcr:38e97667-1cad-42a3-ab4f-09f4df370f55/fruehstueckskarte-schlafwagen.pdf',
    menu: 'https://www.nightjet.com/dam/jcr:57df4df9-c7b6-45f6-8adc-7d850bf914b4/speisekarte-schlafwagen.pdf',
  },
  {
    type: 'comfortPlus',
    price_ratio: 2.35,
    pictures: [
      '/assets/night-trains-images/nightjet-newgen/comfort-plus.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort-plus2.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort-plus3.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort-plus4.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort-plus5.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort-plus6.jpg',
      '/assets/night-trains-images/nightjet-newgen/comfort-plus7.jpg',
    ],
    pictures_author: 'ÖBB/Harald Eisenberger',
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'shower',
      'toilet',
      'breakfast',
      'pillow',
      'blanket',
      'waterBottle',
    ],
    breakfastMenu:
      'https://www.nightjet.com/dam/jcr:38e97667-1cad-42a3-ab4f-09f4df370f55/fruehstueckskarte-schlafwagen.pdf',
    menu: 'https://www.nightjet.com/dam/jcr:57df4df9-c7b6-45f6-8adc-7d850bf914b4/speisekarte-schlafwagen.pdf',
  },
];
const IntercitesDeNuitCabins = [
  {
    type: 'seat',
    price_ratio: 0.76,
    min_price: 19,
    max_price: 119,
    pictures: ['/assets/night-trains-images/intercites/seat.jpg'],
    pictures_author: 'Mark Smith',
    amenities: ['wifi', 'power', 'air', 'reading'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    min_price: 29,
    max_price: 169,
    default: true,
    pictures: [
      '/assets/night-trains-images/intercites/couchette.jpg',
      '/assets/night-trains-images/intercites/couchette2.jpg',
      '/assets/night-trains-images/intercites/couchette3.jpg',
      '/assets/night-trains-images/intercites/couchette4.jpg',
      '/assets/night-trains-images/intercites/couchette5.jpg',
    ],
    picture_author: 'Mark Smith',
    amenities: ['wifi', 'power', 'air', 'reading', 'toiletries'],
  },
  {
    type: 'sleeper',
    price_ratio: 1.31,
    min_price: 49,
    max_price: 260,
    pictures: [
      '/assets/night-trains-images/intercites/premiere.jpg',
      '/assets/night-trains-images/intercites/premiere2.jpg',
      '/assets/night-trains-images/intercites/premiere3.jpg',
      '/assets/night-trains-images/intercites/premiere4.jpg',
    ],
    pictures_author: 'Mark Smith',
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'showerInParis',
      'toilet',
      'breakfast',
    ],
  },
];
const RefurbishedNightjetCabins = [
  {
    type: 'seat',
    price_ratio: 0.46,
    pictures: [
      '/assets/night-trains-images/nightjet/seat.jpg',
      '/assets/night-trains-images/nightjet/seat2.jpg',
    ],
    pictures_author: 'ÖBB/Harald Eisenberger',
    amenities: ['wifi', 'power', 'air', 'reading'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    default: true,
    pictures: [
      '/assets/night-trains-images/refurbishednightjet/nightjet-comfort-couchette-large1.jpg',
      '/assets/night-trains-images/refurbishednightjet/nightjet-comfort-couchette-large2.jpg',
      '/assets/night-trains-images/refurbishednightjet/nightjet-comfort-couchette-large3.jpg',
      '/assets/night-trains-images/refurbishednightjet/nightjet-comfort-couchette-large4.jpg',
    ],
    pictures_author: 'Mark Smith',
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'toiletries',
      'pillow',
      'blanket',
      'waterBottle',
      'breakfast',
    ],
    menu: 'https://www.nightjet.com/dam/jcr:5dc4bcc6-8840-47b8-973a-3a7db2cf3c44/speisekarte-liegewagen.pdf',
  },
  {
    type: 'sleeper',
    price_ratio: 1.925,
    pictures: [
      '/assets/night-trains-images/refurbishednightjet/nightjet-sleeper1-large.jpg',
      '/assets/night-trains-images/refurbishednightjet/nightjet-sleeper2-large.jpg',
      '/assets/night-trains-images/refurbishednightjet/nightjet-sleeper3-large.jpg',
      '/assets/night-trains-images/refurbishednightjet/nightjet-sleeper4-large.jpg',
    ],
    pictures_author: 'Mark Smith',
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'toiletries',
      'pillow',
      'blanket',
      'waterBottle',
      'breakfast',
    ],
    breakfastMenu:
      'https://www.nightjet.com/dam/jcr:38e97667-1cad-42a3-ab4f-09f4df370f55/fruehstueckskarte-schlafwagen.pdf',
    menu: 'https://www.nightjet.com/dam/jcr:57df4df9-c7b6-45f6-8adc-7d850bf914b4/speisekarte-schlafwagen.pdf',
  },
];
const NightjetCabins = [
  {
    type: 'seat',
    price_ratio: 0.46,
    pictures: [
      '/assets/night-trains-images/nightjet/seat.jpg',
      '/assets/night-trains-images/nightjet/seat2.jpg',
    ],
    pictures_author: 'ÖBB/Harald Eisenberger',
    amenities: ['wifi', 'power', 'air', 'reading'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    default: true,
    pictures: [
      '/assets/night-trains-images/nightjet/couchette.jpg',
      '/assets/night-trains-images/nightjet/couchette2.jpg',
      '/assets/night-trains-images/nightjet/couchette3.jpg',
    ],
    pictures_author: 'ÖBB/Harald Eisenberger',
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'toiletries',
      'pillow',
      'blanket',
      'waterBottle',
      'breakfast',
    ],
    menu: 'https://www.nightjet.com/dam/jcr:5dc4bcc6-8840-47b8-973a-3a7db2cf3c44/speisekarte-liegewagen.pdf',
  },
  {
    type: 'sleeper',
    price_ratio: 1.925,
    pictures: [
      '/assets/night-trains-images/nightjet/sleeper.jpg',
      '/assets/night-trains-images/nightjet/sleeper2.jpg',
      '/assets/night-trains-images/nightjet/sleeper3.jpg',
      '/assets/night-trains-images/nightjet/sleeper4.jpg',
      '/assets/night-trains-images/nightjet/sleeper5.jpg',
      '/assets/night-trains-images/nightjet/sleeper6.jpg',
    ],
    pictures_author: 'ÖBB/Harald Eisenberger',
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'toiletries',
      'pillow',
      'blanket',
      'waterBottle',
      'breakfast',
    ],
    breakfastMenu:
      'https://www.nightjet.com/dam/jcr:38e97667-1cad-42a3-ab4f-09f4df370f55/fruehstueckskarte-schlafwagen.pdf',
    menu: 'https://www.nightjet.com/dam/jcr:57df4df9-c7b6-45f6-8adc-7d850bf914b4/speisekarte-schlafwagen.pdf',
  },
];
const EuropeanSleeperCabins = [
  {
    type: 'seat',
    price_ratio: 0.62,
    min_price: 29,
    max_price: 79,
    pictures: ['/assets/night-trains-images/europeansleeper/seat.jpg'],
    amenities: ['wifi', 'power', 'air', 'reading'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    min_price: 49,
    max_price: 159,
    default: true,
    pictures: [
      '/assets/night-trains-images/europeansleeper/couchette.jpg',
      '/assets/night-trains-images/europeansleeper/couchette2.jpg',
    ],
    amenities: ['wifi', 'power', 'air', 'reading', 'toiletries'],
  },
  {
    type: 'sleeper',
    price_ratio: 2.14,
    min_price: 99,
    max_price: 299,
    pictures: [
      '/assets/night-trains-images/europeansleeper/sleeper.jpg',
      '/assets/night-trains-images/europeansleeper/sleeper2.jpg',
      '/assets/night-trains-images/europeansleeper/sleeper3.jpg',
      '/assets/night-trains-images/europeansleeper/sleeper4.jpg',
    ],
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'sink',
      'toilet',
      'breakfast',
      'toiletries',
    ],
  },
];
const SnalltagetCabins = [
  {
    type: 'seat',
    price_ratio: 0.62,
    min_price: 49,
    max_price: 59,
    pictures: ['/assets/night-trains-images/snalltaget/seat.jpg'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    min_price: 100,
    max_price: 169,
    default: true,
    pictures: ['/assets/night-trains-images/snalltaget/couchette.jpg'],
  },
  {
    type: 'sleeper',
    price_ratio: 2.14,
    min_price: 120,
    max_price: 299,
    pictures: ['/assets/night-trains-images/snalltaget/sleeper.jpg'],
  },
];
const CaledonianSleeperCabins = [
  {
    type: 'seat',
    price_ratio: 0.47,
    min_price: 117,
    max_price: 210,
    pictures: [
      '/assets/night-trains-images/caledonian-sleeper/seat.jpg',
      '/assets/night-trains-images/caledonian-sleeper/seat2.jpg',
      '/assets/night-trains-images/caledonian-sleeper/seat3.jpg',
      '/assets/night-trains-images/caledonian-sleeper/seat4.jpg',
    ],
    pictures_author: null,
    amenities: ['wifi', 'power', 'air', 'reading', 'orderService'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    min_price: 310,
    max_price: 350,
    default: true,
    pictures: [
      '/assets/night-trains-images/caledonian-sleeper/couchette.jpg',
      '/assets/night-trains-images/caledonian-sleeper/couchette2.jpg',
      '/assets/night-trains-images/caledonian-sleeper/couchette3.jpg',
      '/assets/night-trains-images/caledonian-sleeper/couchette4.jpg',
    ],
    pictures_author: null,
    amenities: ['wifi', 'power', 'air', 'reading', 'orderService', 'sink'],
  },
  {
    type: 'club-suite',
    price_ratio: 1.25,
    min_price: 374,
    max_price: 473,
    pictures: [
      '/assets/night-trains-images/caledonian-sleeper/clubSuite.jpg',
      '/assets/night-trains-images/caledonian-sleeper/clubSuite2.jpg',
      '/assets/night-trains-images/caledonian-sleeper/clubSuite3.jpg',
    ],
    pictures_authors: 'Caledonian Sleeper',
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'orderService',
      'shower',
      'sink',
      'breakfast',
      'toiletries',
    ],
  },
  {
    type: 'double-suite',
    price_ratio: 1.415,
    min_price: 468,
    max_price: 526,
    pictures: [
      '/assets/night-trains-images/caledonian-sleeper/doubleSuite.jpg',
      '/assets/night-trains-images/caledonian-sleeper/doubleSuite2.jpg',
      '/assets/night-trains-images/caledonian-sleeper/doubleSuite3.jpg',
      '/assets/night-trains-images/caledonian-sleeper/doubleSuite4.jpg',
      '/assets/night-trains-images/caledonian-sleeper/doubleSuite5.jpg',
    ],
    pictures_author: null,
    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'orderService',
      'shower',
      'sink',
      'breakfast',
      'toiletries',
    ],
  },
];
const IntercityNotteCabins = [
  {
    type: 'seat',
    price_ratio: 0.62,
    pictures: ['/assets/night-trains-images/intercity-notte/seat.jpg'],
    pictures_author: 'Mark Smith',
    amenities: ['wifi', 'power', 'air', 'reading'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    default: true,
    pictures: [
      '/assets/night-trains-images/intercity-notte/couchette.jpg',
      '/assets/night-trains-images/intercity-notte/couchette2.jpg',
      '/assets/night-trains-images/intercity-notte/couchette3.jpg',
    ],
    pictures_author: 'Mark Smith',

    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'breakfast',
      'waterBottle',
      'toiletries',
    ],
  },
  {
    type: 'triple-sleeper',
    price_ratio: 2.34,
    pictures: [
      '/assets/night-trains-images/intercity-notte/triple-sleeper.jpg',
      '/assets/night-trains-images/intercity-notte/triple-sleeper2.jpg',
    ],
    pictures_author: 'Mark Smith',

    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'breakfast',
      'waterBottle',
      'toiletries',
    ],
  },
  {
    type: 'double-sleeper',
    price_ratio: 2.14,
    pictures: [
      '/assets/night-trains-images/intercity-notte/double-sleeper.jpg',
      '/assets/night-trains-images/intercity-notte/double-sleeper2.jpg',
    ],
    pictures_author: 'Mark Smith',

    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'breakfast',
      'waterBottle',
      'toiletries',
    ],
  },
  {
    type: 'single-sleeper',
    price_ratio: 2.04,
    pictures: [
      '/assets/night-trains-images/intercity-notte/single-sleeper.jpg',
      '/assets/night-trains-images/intercity-notte/single-sleeper2.jpg',
      '/assets/night-trains-images/intercity-notte/single-sleeper3.jpg',
    ],
    pictures_author: 'Mark Smith',

    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'breakfast',
      'waterBottle',
      'toiletries',
    ],
  },

  {
    type: 'superior-sleeper',
    price_ratio: 2.54,
    pictures: [
      '/assets/night-trains-images/intercity-notte/superior-sleeper.jpg',
      '/assets/night-trains-images/intercity-notte/superior-sleeper2.jpg',
      '/assets/night-trains-images/intercity-notte/superior-sleeper3.jpg',
    ],
    pictures_author: 'Mark Smith',

    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'breakfast',
      'waterBottle',
      'toiletries',
      'shower',
      'sink',
    ],
  },
];
const GWRCabins = [
  {
    type: 'seat',
    price_ratio: 0.8,
    pictures: ['/assets/night-trains-images/gwr/seat.jpg'],
    pictures_author: 'Mark Smith',

    amenities: ['wifi', 'power', 'air', 'reading'],
  },
  {
    type: 'double-sleeper',
    price_ratio: 1,
    default: true,
    pictures: ['/assets/night-trains-images/gwr/double-sleeper.jpg'],
    pictures_author: 'Mark Smith',

    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'toiletries',
      'sink',
      'blanket',
      'pillow',
      'towel',
      'soap',
      'waterBottle',
      'breakfast',
      'GWRLounge',
    ],
  },
  {
    type: 'single-sleeper',
    price_ratio: 1.34,
    pictures: ['/assets/night-trains-images/gwr/single-sleeper.jpg'],
    pictures_author: 'Mark Smith',

    amenities: [
      'wifi',
      'power',
      'air',
      'reading',
      'toiletries',
      'sink',
      'blanket',
      'pillow',
      'towel',
      'soap',
      'waterBottle',
      'breakfast',
      'GWRLounge',
    ],
  },
];
const VYCabins = [
  {
    type: 'seat',
    price_ratio: 0.62,
    pictures: ['/assets/night-trains-images/vy/seat.webp'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    default: true,
    pictures: [
      '/assets/night-trains-images/vy/couchette.jpg',
      '/assets/night-trains-images/vy/couchette2.jpeg',
    ],
  },
  {
    type: 'plus-night',
    price_ratio: 1.31,
    pictures: [
      '/assets/night-trains-images/vy/plusNight.jpg',
      '/assets/night-trains-images/vy/plusNight2.jpg',
      '/assets/night-trains-images/vy/plusNight3.jpg',
      '/assets/night-trains-images/vy/plusNight4.jpg',
    ],
  },
  {
    type: 'sleeper',
    price_ratio: 1.925,
    pictures: ['/assets/night-trains-images/vy/sleeper.jpg'],
  },
];
const HŽPPCabins = [
  {
    type: 'seat',
    price_ratio: 0.62,
    pictures: [
      '/assets/night-trains-images/hzpp/seat.jpg',
      '/assets/night-trains-images/hzpp/seat2.jpg',
      '/assets/night-trains-images/hzpp/seat3.jpg',
    ],
    amenities: ['air', 'power'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    default: true,
    pictures: ['/assets/night-trains-images/hzpp/couchette.avif'],
    amenities: ['air', 'pillow', 'blanket', 'waterBottle'],
  },
  {
    type: 'sleeper',
    price_ratio: 1.925,
    pictures: [
      '/assets/night-trains-images/hzpp/sleeper.jpg',
      '/assets/night-trains-images/hzpp/sleeper2.jpg',
      '/assets/night-trains-images/hzpp/sleeper3.jpg',
      '/assets/night-trains-images/hzpp/sleeper4.jpg',
      '/assets/night-trains-images/hzpp/sleeper5.jpg',
    ],
    amenities: ['air', 'pillow', 'blanket', 'waterBottle', 'sink'],
  },
];

const STARTCabins = [
  {
    type: 'seat',
    price_ratio: 0.62,
    pictures: [
      '/assets/night-trains-images/start/seat.jpg',
      '/assets/night-trains-images/start/seat2.jpg',
      '/assets/night-trains-images/start/seat3.jpg',
    ],
    pictures_author: 'Mark Smith',
    amenities: ['power', 'air'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    default: true,
    pictures: [
      '/assets/night-trains-images/start/couchette.jpg',
      '/assets/night-trains-images/start/couchette2.jpg',
      '/assets/night-trains-images/start/couchette3.avif',
    ],
    pictures_author: 'Mark Smith',
    amenities: ['air', 'pillow', 'blanket', 'waterBottle', 'breakfast', 'foodShop'],
  },
  {
    type: 'triple-sleeper',
    price_ratio: 1.925,
    pictures: [
      '/assets/night-trains-images/start/triple-sleeper.jpg',
      '/assets/night-trains-images/start/triple-sleeper2.jpg',
    ],
    pictures_author: 'Mark Smith',
    amenities: ['air', 'pillow', 'blanket', 'drinks', 'breakfast'],
  },
  {
    type: 'double-sleeper',
    price_ratio: 1.925,
    pictures: [
      '/assets/night-trains-images/start/double-sleeper.jpg',
      '/assets/night-trains-images/start/double-sleeper2.jpg',
      '/assets/night-trains-images/start/double-sleeper3.jpg',
    ],
    pictures_author: 'Mark Smith',
    amenities: ['air', 'pillow', 'blanket', 'drinks', 'breakfast'],
  },
  {
    type: 'single-sleeper',
    price_ratio: 1.925,
    pictures: [
      '/assets/night-trains-images/start/double-sleeper2.jpg',
      '/assets/night-trains-images/start/single-sleeper2.jpg',
      '/assets/night-trains-images/start/single-sleeper3.jpg',
    ],
    pictures_author: 'Mark Smith',
    description:
      'Sleeper berths are sold individually, if you dont want to pay for a single-bed sleeper you can book one bed in a 2 or 3 berth and share with another passenger of the same gender.  Compartments are single-sex unless your party occupies the whole compartment.  An excellent way to travel.',
    amenities: ['air', 'pillow', 'blanket', 'drinks', 'breakfast'],
  },
];
const SJCabins = [
  {
    type: 'seat',
    price_ratio: 0.62,
    pictures: ['/assets/night-trains-images/sj/seat.jpg'],
  },
  {
    type: 'couchette',
    price_ratio: 1,
    default: true,
    pictures: ['/assets/night-trains-images/sj/couchette.jpg'],
    amenities: ['power'],
  },
  {
    type: 'double-sleeper',
    price_ratio: 1.925,
    pictures: [
      '/assets/night-trains-images/sj/sleeper.jpg',
      '/assets/night-trains-images/sj/sleeper2.jpg',
    ],
    amenities: ['power', 'breakfast', 'sink'],
  },
  {
    type: 'deluxe-double-sleeper',
    price_ratio: 2.155,
    pictures: [
      '/assets/night-trains-images/sj/deluxe-sleeper.jpg',
      '/assets/night-trains-images/sj/deluxe-sleeper2.jpg',
      '/assets/night-trains-images/sj/deluxe-sleeper3.jpg',
    ],
    amenities: ['power', 'breakfast', 'shower', 'toilet'],
  },
];
const PKPCabins = [
  {
    type: 'triple-sleeper',
    price_ratio: 2.155,
    min_price: 124,
    max_price: 214,
    pictures: ['/assets/night-trains-images/pkpic/triple-sleeper.jpg'],
  },

  {
    type: 'double-sleeper',
    price_ratio: 1,
    min_price: 179,
    max_price: 264,
    default: true,
    pictures: ['/assets/night-trains-images/pkpic/double-sleeper.jpg'],
  },
  {
    type: 'single-sleeper',
    price_ratio: 1.925,
    min_price: 359,
    max_price: 504,
    pictures: ['/assets/night-trains-images/pkpic/single-sleeper.jpg'],
  },
  {
    type: 'deluxe-double-sleeper',
    price_ratio: 2.155,
    min_price: 159,
    max_price: 309,
    pictures: [
      '/assets/night-trains-images/pkpic/deluxe-double-sleeper.jpg',
      '/assets/night-trains-images/pkpic/deluxe-double-sleeper2.jpg',
      '/assets/night-trains-images/pkpic/deluxe-double-sleeper3.jpg',
      '/assets/night-trains-images/pkpic/deluxe-sleeper-food.jpg',
      '/assets/night-trains-images/pkpic/deluxe-sleeper-bathroom.jpg',
      '/assets/night-trains-images/pkpic/deluxe-sleeper-sink.jpg',
    ],
  },
  {
    type: 'deluxe-single-sleeper',
    price_ratio: 2.355,
    min_price: 399,
    max_price: 614,
    pictures: [
      '/assets/night-trains-images/pkpic/deluxe-single-sleeper.jpg',
      '/assets/night-trains-images/pkpic/deluxe-sleeper-food.jpg',
      '/assets/night-trains-images/pkpic/deluxe-sleeper-bathroom.jpg',
      '/assets/night-trains-images/pkpic/deluxe-sleeper-sink.jpg',
    ],
  },
];
const nightTrainServices = {
  VY: {
    services: ['Vy', 'VY 605', 'VY 606'],
    cabins: VYCabins,
    trad_key: 'VY',
  },
  EuropeanSleeper: {
    services: ['ES452', 'ES453'],
    cabins: EuropeanSleeperCabins,
    trad_key: 'EuropeanSleeper',
  },
  Snalltaget: {
    services: ['10300', 'Snälltåget'],
    cabins: SnalltagetCabins,
    trad_key: 'Snalltaget',
  },
  IntercityNotte: {
    services: [
      'ICN',
      'ICN 1963',
      'ICN 1964',
      'ICN 1967',
      'ICN 770',
      'ICN 774',
      'ICN 765',
      'ICN 758',
      'ICN 797',
      'ICN 798',
    ],
    cabins: IntercityNotteCabins,
    trad_key: 'IntercityNotte',
  },
  IntercitesDeNuit: {
    services: ['Intercités De Nuit', 'Intercités de nuit'],
    cabins: IntercitesDeNuitCabins,
    trad_key: 'IntercitesDeNuit',
  },
  CaledonianSleeper: {
    services: ['Caledonian Sleeper', 'Lowlander', 'Highlander'],
    cabins: CaledonianSleeperCabins,
    trad_key: 'CaledonianSleeper',
  },
  GWR: {
    services: ['Night Riviera'],
    cabins: GWRCabins,
    trad_key: 'GWR',
  },
  NightjetNewGen: {
    services: [
      'NJ 295',
      'NJ 294',
      'NJ 40491',
      'NJ 40420',
      'NJ 40233',
      'NJ 40294',
      'NJ 490',
      'NJ 491',
      'NJ 447',
      'NJ 446',
    ],
    cabins: NightjetNewGenCabins,
    trad_key: 'NightjetNewGen',
  },
  RefurbishedNightjet: {
    services: ['NJ 467', 'NJ 470', 'NJ 466', 'NJ 400'],
    cabins: RefurbishedNightjetCabins,
    trad_key: 'Nightjet',
  },
  Nightjet: {
    services: ['Nightjet', 'NJ', 'NJ 40466', 'NJ 40295', 'NJ 40235'],
    cabins: NightjetCabins,
    trad_key: 'Nightjet',
  },
  EuroNightjet: {
    services: ['EN', 'EN 235', 'EN 234', 'EN 235', 'EN 234', 'EN 50236', 'EN 40466'],
    cabins: NightjetCabins,
    trad_key: 'EuroNight',
  },
  EuroNightHŽPP: {
    services: ['EN 40237', 'EN 60237', 'EN 480', 'EN 414', 'EN 40465'],
    cabins: HŽPPCabins,
    trad_key: 'EuroNight',
  },
  EuroNightSTART: {
    services: ['EN 50237', 'EN 40457', 'EN 477', 'EN 50462', 'EN 40467'],
    cabins: STARTCabins,
    trad_key: 'EuroNight',
  },
  EuroNightCD: {
    services: ['EN 50467', 'EN 408', 'EN 443', 'EN 40459', 'EN 40458'],
    cabins: NightjetCabins,
    //CD cabins to add https://www.vagonweb.cz/fotogalerie/CZ/CD_WLABmz.php
    trad_key: 'EuroNight',
  },
  EuroNightSJ: {
    services: ['EN 345', 'EN 346', '346'],
    cabins: SJCabins,
    trad_key: 'SJ',
  },
  EuroNightPKPIC: {
    services: ['EN 406', 'EN 407'],
    cabins: PKPCabins,
    trad_key: 'EuroNight',
  },
};

export const isaNightTrainService = (service: { name: string }): boolean => {
  return Object.keys(nightTrainServices).some((key) =>
    nightTrainServices[key]?.services
      .map((serv) => serv.toLowerCase())
      .includes(service?.name.toLowerCase())
  );
};

export interface Cabin {
  type: string;
  //Ratio to default cabin option
  price_ratio: number;
  min_price?: number;
  max_price?: number;
  pictures: string[];
  pictures_author?: string;
  default?: boolean;
  description?: string;
  amenities?: string[];
  menu?: string;
  breakfastMenu?: string;
}

export interface CabinsDetails {
  operatorKey: string;
  cabins: Cabin[];
}

export function getCabinDetails(service: {
  name: string;
}): CabinsDetails | { operatorKey: null; cabins: null } {
  const operatorKey = Object.keys(nightTrainServices).find((key) =>
    nightTrainServices[key]?.services
      .map((serv) => serv.toLowerCase())
      .includes(service?.name.toLowerCase())
  );

  if (!operatorKey) {
    return { operatorKey: null, cabins: null };
  }

  return {
    operatorKey: nightTrainServices[operatorKey].trad_key,
    cabins: nightTrainServices[operatorKey].cabins,
  };
}
