'use client';

import { useEffect, useState } from 'react';

import { useTranslations } from 'next-intl';

import { Cabin } from 'lib/nightTrainsCabinDetails';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import { Badge } from '../ui/badge';
import { Card } from '../ui/card';
import { Slider } from '../ui/slider';

interface Props {
  amount: number | null;
  selectedCabinType: string;
  cabins: Cabin[];
}

const PriceHistory = ({ amount, selectedCabinType, cabins }: Props) => {
  const t = useTranslations('Tickets.priceHistory');
  const [color, setColor] = useState<string>('');
  const [adjective, setAdjective] = useState<string>('');
  const cabin = cabins.find((_cabin) => _cabin.type === selectedCabinType);
  const offerPrice = (amount ?? 0) * (cabin?.price_ratio ?? 0);
  const maxOfferPrice = cabin?.max_price ?? 0;
  const minOfferPrice = cabin?.min_price ?? 0;

  useEffect(() => {
    const range = maxOfferPrice - minOfferPrice;
    if (range === 0) {
      return;
    }
    const position = (offerPrice - minOfferPrice) / range;
    let newColor = '';
    let newAdjective = '';

    if (position > 2 / 3) {
      newColor = 'red';
      newAdjective = 'high';
    } else if (position < 1 / 3) {
      newColor = 'green';
      newAdjective = 'low';
    } else {
      newColor = 'yellow';
      newAdjective = 'average';
    }

    setColor(newColor);
    setAdjective(newAdjective);
  }, [amount, selectedCabinType]);

  const someDataIsMissing = !amount || !offerPrice || !minOfferPrice || !maxOfferPrice;
  if (someDataIsMissing) {
    return null;
  }

  return (
    <Card className="mt-4 bg-yellow-200 border-yellow-200 bg-opacity-20 mb-5 w-full">
      <Accordion type="single" collapsible className="mx-4">
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <p className="text-sm text-gray-500 text-left mr-6">
              {t.rich('title', {
                adjective: t(`variant.${adjective}`),
                color: (chunks) => <b className={`text-${color}-500`}>{chunks}</b>,
              })}
            </p>
          </AccordionTrigger>

          <AccordionContent>
            <div className="flex flex-col justify-start mt-2">
              <div
                className={`flex ${adjective == 'low' ? 'justify-start ml-2' : adjective == 'average' ? 'justify-center' : 'justify-end'} items-center`}
              >
                <Badge
                  variant="secondary"
                  className={`bg-${color}-200 text-${color}-600`}
                >
                  {t(`badge.${adjective}`, { price: offerPrice })}
                </Badge>
              </div>
              <div className="flex items-center h-6 mx-4">
                <Slider
                  disabled
                  gradient
                  className="w-full"
                  min={minOfferPrice}
                  max={maxOfferPrice}
                  value={[offerPrice]}
                />
              </div>
              <div className="flex flex-row justify-between w-full">
                <p className="text-sm text-gray-500">{minOfferPrice}€</p>
                <p className="text-sm text-gray-500">{maxOfferPrice}€</p>
              </div>
              <p className="mt-2 text-slate-700">
                {t('subtitle', { max: maxOfferPrice })}
              </p>
              {
                // eslint-disable-next-line no-commented-code/no-commented-code
                /*
              <Accordion type="single" collapsible className='mx-4'>
                <AccordionItem value="first-item">
                  <AccordionTrigger className="flex flex-row justify-center items-center">
                    <p className="text-sm text-gray-500">Price in the last 2 weeks</p>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div id="chart" className="w-full">
                      <LineChart width={500} height={300} data={chartData} margin={{ top: 5, right: 0, bottom: 5, left: 0 }}>
                        <Line type="monotone" dataKey="price" stroke="#8884d8" />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                      </LineChart>
                    </div>
                  </AccordionContent>
                </AccordionItem>

              </Accordion>
             */
              }
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
export default PriceHistory;
